
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "minat-bakat-mrk",
  components: {
  },
  setup() {
    var participantCache = ref([] as Array<any>)
    var graphCache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_MINAT_BAKAT
    let isDialog = ref(false)
    let itemsDialog = ref({})
    var tot_like = ref(0)
    var tot_dislike = ref(0)
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-warning");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const series = ref([] as Array<any>);
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("MINAT BAKAT", [])
      loadData()
    })

    async function loadData(){
      participantCache.value = []
      await Services.GetData(ApiService2, `result/tipetest`, TypeTestID, response=>{
          participantCache.value = response.data
      }, err =>{
        err
      })
    }

    async function openModal(item){
     graphCache.value = []
      await Services.GetData(ApiService2, `result/tokenandpartisipantminatbakat`, item.session+'/'+ item.participant_id, response=>{
          itemsDialog.value = item
          graphCache.value = response.data
          mappingChart()
          isDialog.value = true
      }, err =>{
        err
      })
    }

    function mappingChart(){
      series.value = [] 
      var likeItems = {name: "Like", data:[]}
      var dislikeItems = {name: "Dislike", data:[]}
      var opt = options.value
      opt.xaxis.categories = []
      for (var i = 0; i < graphCache.value.length; i++) {
          tot_like.value = tot_like.value + Number(graphCache.value[i]['suka'])
          tot_dislike.value = tot_dislike.value + Number(graphCache.value[i]['tdk_suka'])
          likeItems['data'].push(Number(graphCache.value[i]['suka']))
          dislikeItems['data'].push(Number(graphCache.value[i]['tdk_suka']))
          opt.xaxis.categories.push('grup: '+ graphCache.value[i]['title_group'])
      }
      series.value.push(likeItems, dislikeItems)
    }

    function closeModal(){
      isDialog.value = false
      itemsDialog.value = {}
    }

    return {
      participantCache,
      graphCache,
      openModal,
      closeModal,
      isDialog,
      itemsDialog,
      options,
      series,
      tot_like,
      tot_dislike,
    };
  },
});
